@use '@angular/material' as mat;

@use 'sass:map';
@use '../../../../auth/src/lib/component/login-error/_login-error.component.scss' as login_error;
@use '../../../../caig-utils/src/lib/component/themed-background/_themed-background.component.scss' as app_bg;

// Styles for the docs app that are based on the current theme.
@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $next-theme: mat.define-palette(mat.$red-palette);

  .docs-app-background {
    background: mat.get-color-from-palette($background, background);
  }

  .docs-primary-header {
    background: mat.get-color-from-palette($primary);

    h1 {
      color: mat.get-color-from-palette($primary, default-contrast);
    }
  }

  .docs-footer {
    background: mat.get-color-from-palette($primary);
    color: mat.get-color-from-palette($primary, default-contrast);
  }

  .docs-help-support {
    color: mat.get-color-from-palette($foreground, secondary-text);

    a {
      color: mat.get-color-from-palette($foreground, text);
    }
  }

  .is-next-version {
    background: mat.get-color-from-palette($next-theme, 900) !important;
  }

  @include login_error.theme($theme);
  @include app_bg.theme($theme);
}
