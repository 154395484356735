@use '@angular/material' as mat;
@use './layout';
@use './themes/app-theme';
@tailwind utilities;

html, body { height: 100%; }
body { margin: 0;font-family: Roboto, "Helvetica Neue", sans-serif; }

// Define the light theme.
$primary: mat.define-palette(mat.$indigo-palette);
$accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent
  ),
  typography: mat.define-typography-config(),
));

// Include material core styles.
@include mat.core();

@include mat.all-component-themes($theme);
@include app-theme.theme($theme);

.ui-datatable-scrollable-body {
  overflow-anchor: none;
}

.stretch-badge > .mat-badge-content {
  width: auto !important;
  border-radius: 13px;
  min-width: 16px;
  min-height: 22px;
  padding: 0 3px;
  right: unset !important;
}

button.example-custom-date-class {
  background: orange;
  border-radius: 100%;
}
