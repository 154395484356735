@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $is-dark-theme: map.get($color-config, 'is-dark');

  .app-background {
    background-image: if($is-dark-theme, var(--bg-dark), var(--bg-light));
  }
}
