/* fxFlexFill */
.flex-fill {
  display: flex;
  height: 100%;
}

.flex {
  display: flex;
}

/* fxFlex (with grow factor 0, shrink factor 1, and base size auto) */
.flex-0 {
  flex: 0 1 auto;
}

/* fxFlex (with grow factor 1, shrink factor 1, and base size 0%) */
.flex-1 {
  flex: 1 1 0;
}

/* fxLayout */
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

/* start start */
.flex-align-start-start {
  justify-content: flex-start;
  align-items: flex-start;
}

/* center start */
.flex-align-center-start {
  justify-content: center;
  align-items: flex-start;
}

/* end start */
.flex-align-end-start {
  justify-content: flex-end;
  align-items: flex-start;
}

/* space-around start */
.flex-align-spacearound-start {
  justify-content: space-around;
  align-items: flex-start;
}

/* space-between start */
.flex-align-spacebetween-start {
  justify-content: space-between;
  align-items: flex-start;
}

/* start center */
.flex-align-start-center {
  justify-content: flex-start;
  align-items: center;
}

/* center center */
.flex-align-center-center {
  justify-content: center;
  align-items: center;
}

/* end center */
.flex-align-end-center {
  justify-content: flex-end;
  align-items: center;
}

/* space-around center */
.flex-align-spacearound-center {
  justify-content: space-around;
  align-items: center;
}

/* space-between center */
.flex-align-spacebetween-center {
  justify-content: space-between;
  align-items: center;
}

/* start end */
.flex-align-start-end {
  justify-content: flex-start;
  align-items: flex-end;
}

/* center end */
.flex-align-center-end {
  justify-content: center;
  align-items: flex-end;
}

/* end end */
.flex-align-end-end {
  justify-content: flex-end;
  align-items: flex-end;
}

/* space-around end */
.flex-align-spacearound-end {
  justify-content: space-around;
  align-items: flex-end;
}

/* space-between end */
.flex-align-spacebetween-end {
  justify-content: space-between;
  align-items: flex-end;
}

/* start stretch */
.flex-align-start-stretch {
  justify-content: flex-start;
  align-items: stretch;
}

/* center stretch */
.flex-align-center-stretch {
  justify-content: center;
  align-items: stretch;
}

/* end stretch */
.flex-align-end-stretch {
  justify-content: flex-end;
  align-items: stretch;
}

/* space-around stretch */
.flex-align-spacearound-stretch {
  justify-content: space-around;
  align-items: stretch;
}

/* space-between stretch */
.flex-align-spacebetween-stretch {
  justify-content: space-between;
  align-items: stretch;
}
