@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $warn-palette: map.get($color-config, 'warn');
  $is-dark-theme: map.get($color-config, 'is-dark');

  $background-color: mat.get-color-from-palette($warn-palette, 500);
  $vignette-color: mat.get-color-from-palette($warn-palette, 700);

  $text-color: mat.get-contrast-color-from-palette($warn-palette, 500);

  .login-error {
    background-color: $background-color;
    background-image: radial-gradient(circle at center, transparent, $vignette-color);
    color: $text-color;
    border-color: if($is-dark-theme, white, black) !important;
  }
}
